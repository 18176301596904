
import { Component, Vue, Watch, Prop } from "vue-property-decorator";
import i18n from "../../plugins/i18n";

import Team from "../../models/sportevent/Team";
import TeamService from "../../services/sportevent/TeamService";
import TeamCompetition from "../../models/sportevent/TeamCompetition";
import TeamCompetitionService from "../../services/sportevent/TeamCompetitionService";

@Component({})
export default class TeamsComponent extends Vue {
  @Prop({ required: true })
  public teamCompetitionId!: string;

  public teamCompetition: TeamCompetition = new TeamCompetition();

  // Loading
  public loading = true;
  // Table
  public teams: Team[] = [];

  async getAll(): Promise<void> {
    this.loading = true;
    this.teamCompetition = await TeamCompetitionService.get(
      this.teamCompetitionId
    );
    const teams = await TeamService.getAllByTeamCompetitonId(
      this.teamCompetitionId
    );
    if (teams !== undefined) {
      this.teams = teams;
    }
    this.loading = false;
  }

  async deleteTeam(team: Team): Promise<void> {
    if (team.id != undefined) {
      if (confirm(i18n.tc("labels.confirmDelete"))) {
        await TeamService.delete(team.id);
        this.getAll();
      }
    }
  }

  @Watch("$route", { immediate: true, deep: true })
  onUrlChange(): void {
    this.getAll();
  }

  getAllStarterName(team: Team): string {
    return team.starters
      .map((e) => `${e.person.firstname} ${e.person.lastname}`)
      .join(", ");
  }
}
