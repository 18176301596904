import Society from "@/models/societymgmt/Society";
import BaseService from "../BaseService";
import HttpService from "../HttpService";

class SocietyService extends BaseService<Society> {
  constructor() {
    super("societymgmt/society/", "localstore_societies");
  }

  async uploadSocietyCsv(formData: FormData) {
    const response = await HttpService.post<Record<string, any>>(
      `societymgmt/society-import`,
      formData,
      true
    );
    return response;
  }
}
export default new SocietyService();
