
import { Component, Vue, Watch } from "vue-property-decorator";
import i18n from "../../plugins/i18n";

import formatDate from "../../plugins/formatDate";
import TeamCompetitionService from "../../services/sportevent/TeamCompetitionService";
import TeamCompetition from "../../models/sportevent/TeamCompetition";
import TeamsComponent from "./Teams.vue";
import SporteventService from "../../services/sportevent/SporteventService";
import { read, utils } from 'xlsx';
import Team from "../../models/sportevent/Team";
import StarterService from "../../services/sportevent/StarterService";
import SocietyService from "../../services/societymgmt/SocietyService";
import Society from "../../models/societymgmt/Society";
import TeamService from "../../services/sportevent/TeamService";
import Starter from "../../models/sportevent/Starter";
import { Worksheet } from "exceljs";

@Component({
  components: {
    TeamsComponent,
  },
  filters: {
    formatDate,
  },
})

export default class TeamCompetitionsComponent extends Vue {
  // Loading
  public loading = true;
  // Table
  public teamCompetitions: TeamCompetition[] = [];
  public fields = [
    {
      key: "actions",
      sortable: false,
      class: "text-center",
      label: "",
    },
  ];
  public filter = "";
  public perPage = 10;
  public pageOptions = [5, 10, 20, 30];
  public totalRows = 0;
  public currentPage = 1;
  public sportevent = SporteventService.get();

  public teamImports: Team[] = [];

  async getAll(): Promise<void> {
    this.loading = true;

    if (this.sportevent.id != undefined) {
      const teams = await TeamService.getAllBySportevent(this.sportevent.id);
      if (teams !== undefined) {
        this.teamImports = teams;
      }
      let teamCompetitions = await TeamCompetitionService.getAllBySportevent(
        this.sportevent.id
      );
      if (teamCompetitions === undefined) return;
      this.teamCompetitions = teamCompetitions;
      if (this.teamCompetitions) {
        this.totalRows = this.teamCompetitions.length;
      }
      this.loading = false;
    }
  }

  deleteTeamCompetition(teamCompetition: TeamCompetition): void {
    if (teamCompetition.id != undefined) {
      if (
        confirm(
          i18n.tc("labels.confirmDelete") +
            ` ${teamCompetition.teamCalculation.name}`
        )
      ) {
        TeamCompetitionService.delete(teamCompetition.id).then(() => {
          this.getAll();
        });
      }
    }
  }

  @Watch("$route", { immediate: true, deep: true })
  onUrlChange(): void {
    this.getAll();
  }

   async addTeams(event: any): Promise<void> {
    const file = event.target.files ? event.target.files[0] : null;
    console.log(file);
    //const wb = new Excel.Workbook();
    const reader = new FileReader();

    const starters = await StarterService.getAll(true);
    const societies = await SocietyService.getAll(true);

    reader.readAsArrayBuffer(file);
    reader.onload = () => {
      const wb = read(reader.result);
      
      const starters_from_import = utils.sheet_to_json(wb.Sheets[wb.SheetNames[wb.SheetNames.findIndex(name => name.startsWith("Einzelleistung Ansicht"))]]);

      console.log(starters_from_import);

      if (starters_from_import.length > 0) {
        starters_from_import.forEach((starter_from_import: any) => {
          
          const importFullname: string =          starter_from_import['Starter'] as string;
          const importSociety: string =           starter_from_import['startet für'] as string;
          const importTeamname: string =          starter_from_import['zugehörige Mannschaft'] as string;
          const importCompetitionName: string =   starter_from_import['zugeörige Disziplin'] as string;
          const importTeamOetbWienID: number =    starter_from_import['ID Berechnungsprog. (zugehörige Mannschaft) (Mannschaft)'] as number;
          const importSocietyOetbWienID: number = starter_from_import['ID (Berechnungsprogramm) (startet für) (Verein)'] as number;
          console.log(`${importTeamOetbWienID} - ${importTeamname}`);

          const teamCompetition = this.teamCompetitions.find(
            (tc: TeamCompetition) =>
              tc.sporteventCompetition.competition.name ===
              importCompetitionName
          );
          if (teamCompetition !== undefined) {
            const society = societies.find(
              (s: Society) => s.oetbWienID === importSocietyOetbWienID
            );

            if (society !== undefined) {
              const starter = starters.find( (s: Starter) => 
                  `${s.person.firstname.toLowerCase()} ${s.person.lastname.toLowerCase()}` === importFullname.toLowerCase() &&
                  s.startsForSociety.id === society.id &&
                  s.sporteventCompetition.competition.id === teamCompetition.sporteventCompetition.competition.id
              );
              if (starter !== undefined) {
                let teamIndex = this.teamImports.findIndex(
                  (t: Team) =>
                    t.name === importTeamname &&
                    t.teamCompetition.id === teamCompetition.id
                );
                let team = new Team();
                if (teamIndex === -1) {
                  // New Team
                  team.id = null;
                } else {
                  team = this.teamImports[teamIndex];
                }
                // Update Data
                team.sportevent = SporteventService.get();
                team.society = society;
                team.name = importTeamname;
                team.starters.push(starter);
                team.teamCompetition = teamCompetition;
                team.oetbWienID = importTeamOetbWienID;
                if (teamIndex === -1) {
                  this.teamImports.push(team);
                } else {
                  this.teamImports[teamIndex] = team;
                }
              } else {
                console.log(
                  `Zeile ${starter_from_import['__rowNum__']} Fehler '${importFullname}' für '${importSociety}' - '${importTeamname}' nicht angelegt!`
                );
              }
            } else {
              console.log(
                `Zeile ${starter_from_import['__rowNum__']} Fehler '${importFullname}': Verein mit der OetbWien ID '${importSocietyOetbWienID}' ist unbekannt!`
              );
            }
          } else {
            console.log(
              `Zeile ${starter_from_import['__rowNum__']} Fehler '${importFullname}': Team-Bewerb '${importCompetitionName}' ist nicht angelegt!`
            );
          }
        })
      }
      console.log("Mannschaften", this.teamImports);   
    }
  }

  async startTeamImport(): Promise<void> {
    this.loading = true;
    this.teamImports = await TeamService.saveList(this.teamImports);
    this.getAll();
    this.loading = false;
  }
}
